<template>
  <div>
    <div 
      v-show="viewContext === 0"
      class="dashboard-overlay"
    >
      <div class="clear-filter-container" v-show="false">
        <tds-button
          class="clear-filter-button"
          type="button"
          variant="secondary"
          size="sm"
          :text="translate('data->Clear Filters')"
          @click="clearFilters"
        />
        <div class="metrics-container">
          <customer-fleet-filter
              :key="componentKey"
            :filter-name="'Fleet'"
          />
        </div>
      </div>
      <div class="metrics-container">
        <!-- Fleet Status Card -->
        <div
          class="metric-card"
        >
          <h3>{{ translate("data->Sites with highest standstill") }}<span class="emoji">🏠</span> </h3>
          <div
            v-if="topLocations.length"
            class="metric-content"
          >
            <div
              v-for="location in topLocations.slice(0, 3)"
              :key="location.hexagons"
            >
              <div @click="handleCardClick('fleetStatus',location.hexagons)">
                <p class="location-hex">
                  {{ location.hexagon_name.length > 0 ? location.hexagon_name : resolvedNames[location.hexagons] }}
                </p>
                <p class="duration">
                  {{ translate("data->Duration") }}: {{ location.sum_duration_alias }}
                </p>
                <p class="stops">
                  {{ translate("data->Stops") }}: {{ location.no_stops }}
                </p>
                <p class="saving">
                  {{ translate("data->Potential Saving") }}: {{ location.potential_saving_alias }}
                </p>
                <p class="saving">
                  {{ translate("data->Median Stop Duration") }}: {{ Math.round(location.median_stop_duration*24) }} h
                </p>
                <p class="saving">
                  {{ translate("data->Average Stop Duration") }}: {{ Math.round(location.mean_stop_duration*24) }} h
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- Top vehicles Time Card -->
        <div
          class="metric-card"
        >
          <h3>{{ translate("data->Vehicles with highest standstill") }}<span class="emoji">🚛</span> </h3>
          <div
            v-if="topVehicles.length"
            class="metric-content"
          >
            <div
              v-for="vehicle in topVehicles.slice(0, 3)"
              :key="vehicle.external_equipment_reference"
            >
              <div @click="handleCardClick('vehicleStandStill',vehicle.reg_no)">
                <p class="vehicle-id">
                  {{ vehicle.reg_no }}
                </p>
                <p class="duration">
                  {{ roundStringToNearestInt( vehicle.sum_duration) }}{{ translate("data->days") }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- Standstill Time Card -->
        <div
          class="metric-card"
        >
          <h3>{{ translate("data->Vehicles with highest fuel consumption") }}<span class="emoji">🚚</span> </h3>
          <div
            v-if="topFuelConsumptionVehicles.length"
            class="metric-content"
          >
            <div
              v-for="vehicle in topFuelConsumptionVehicles.slice(0, 3)"
              :key="vehicle.external_equipment_reference"
            >
              <div @click="handleCardClick('fuelConsumption',vehicle.reg_no)">
                <p class="vehicle-id">
                  {{ vehicle.reg_no }}
                </p>
                <p class="duration">
                  {{ roundStringToNearestInt( vehicle.FUEL_CONSUMPTION) }}{{ translate("data-> litre/100km") }}
                </p>
                <p class="stops">
                  {{ translate("data->Total fuel consumption") }}: {{ roundStringToNearestInt(vehicle.sum_outgoing_trip_fuel) }}{{ translate("data-> litre") }}
                </p>
                <p class="stops">
                  {{ translate("data->Total distance driven") }}: {{ roundStringToNearestInt(vehicle.sum_outgoing_trip_odometer) }}{{ translate("data-> km") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import CustomerFleetFilter from "@/components/CustomerFleetFilter.vue"
import { useEventsBinding, useMap } from "@/composables/index"
import { useDataFetcher } from "@/composables/useCustomerDataFetcher"
import { workerManager } from "@/services/duckWorkerManager"
import { mapBoxReverseGeocode } from "@/services/h3Utils"
import { useI18nStore } from "@/store/localeStore"
import { useMainStore } from "@/store/mainStore"
import { useSetStore } from "@/store/setStore"
import { cellToLatLng } from "h3-js"
import { computed, inject,  ref, unref, watch, watchEffect,defineEmits } from "vue"

const props = defineProps({
  viewContext: {
    type: Number,
    required: true
  },
  mapModuleStateName: {
    type: String,
    required: true
  }
})
const componentKey= ref(0)
const get1monthAgoDate = () => {
  if (process.env.VUE_APP_API_CLIENT !== "prod") {
    return "2021-10-01"
  }

  const currentDate = new Date() // Today
  currentDate.setMonth(currentDate.getMonth() - 1) // Subtract 1 month

  const year = currentDate.getFullYear()
  const month = String(currentDate.getMonth() + 1).padStart(2, "0") // Months are 0-indexed
  const day = String(currentDate.getDate()).padStart(2, "0")

  return `${year}-${month}-${day}`
}

const dateRange = ref([
  new Date(get1monthAgoDate()),
  new Date()
])

const dataStore = useSetStore()
const mainStore = useMainStore()
const currentFilters = ref({})
const { map } = useMap()
const event =["layerDataChanged"]
const useCustomStore = inject(props.mapModuleStateName)

// eslint-disable-next-line vue/valid-define-emits
const emit = defineEmits()
useEventsBinding(emit, event, map)


const topLocations = computed(() => {
  const locations = dataStore.getAggregate("topLocations") || []
  return locations
})
const topVehicles = computed(() => {
  const vehicles = dataStore.getAggregate("topVehicles")
  return vehicles
})

const topFuelConsumptionVehicles = computed(() => {
  const vehicles = dataStore.getAggregate("topFuelConsumptionVehicles") || []
  return vehicles
})

function handleCardClick(metricType,filterValue) {
  fetchFilteredData(metricType,filterValue)
  if( metricType == "fleetStatus") {
    flyToOnSelection(filterValue)
  }

}

const flyToOnSelection = (hexId) => {
  let [lat, lng] = transposeCoordinates(hexId)

  // Adjust longitude to skew towards the right to fit the panel
  const latitudeOffset = -0.01
  lat -= latitudeOffset
  const target = {
    center: [lat, lng],
    zoom: 13
  }

  unref(map).flyTo({
    ...target,
    pitch: 60,
    duration: 6000,
    essential: true
  })
}
const transposeCoordinates = h3CellIndex => {
  let invertedCoordinates = cellToLatLng(h3CellIndex)
  return [invertedCoordinates[1], invertedCoordinates[0]]
}
const resolvedNames = ref({})


const clearFilters = () => {
  currentFilters.value = {}

  apiConfigurationsRef.value.forEach(apiConfig => {
    const worker = workerManager.getWorker(apiConfig.workerName)
    const configWithoutFilters = apiConfig.requiresFilter ? {...apiConfig, filters: getFilter()} : apiConfig
    postMessageToDuckAPI(worker, configWithoutFilters)
  })

  componentKey.value++
}

watchEffect(async () => {
  for (const item of topLocations.value) {
    if (!resolvedNames.value[item.hexagons]) {
      try {
        resolvedNames.value[item.hexagons] = await mapBoxReverseGeocode(item.hexagons)
      } catch (error) {
        console.error(`Error resolving name for ${item.hexagons}:`, error)
        resolvedNames.value[item.hexagons] = item.hexagons // Fallback to using the hexagon ID
      }
    }
  }
})

const i18nStore = useI18nStore()
const translate = (key) => {
  return i18nStore.getTranslation(key)
}

function roundStringToNearestInt(str) {
  return Math.round(parseFloat(str))
}

const getFilter = (metricType, filterValue) => {
  const baseFilter = {
    min_date: dateRange.value[0].toISOString().split("T")[0],
    max_date: dateRange.value[1].toISOString().split("T")[0]
  }

  switch (metricType) {
    case "fuelConsumption":
    case "vehicleStandStill":
      return { ...baseFilter, reg_no: filterValue }
    case "fleetStatus":
      return { ...baseFilter, origin: filterValue }
    default:
      return baseFilter
  }
}



const apiConfigurations = [
  { workerName: "hexesAPI", filename: "stops", setName: ["locationAggregates", "locationAggregatesUnfiltered"], queryFunction: "get_location_aggregates", message: "Hexes is requested", requiresFilter: true},
  { workerName: "edgesAPI", filename: "stops", setName: ["edgeAggregates", "edgeAggregatesUnfiltered"], queryFunction: "edges", message: "Edges is requested", requiresFilter: true},
  { workerName: "stopDuration7DaysAPI",filename: "stops", setName: "duration7DaysAggregates", queryFunction: "get_stop_duration_last_week", message: "get_stop_duration_last_week is requested", requiresFilter: true},
  { workerName: "comparedStopDuration7DaysAPI", filename: "stops", setName: "comparedDuration7DaysAggregates", queryFunction: "get_comparison_stop_duration_last_week", message: "get_comparison_stop_duration_last_week is requested", requiresFilter: true},
  { workerName: "stopDurationPredictionAPI", filename: "stops", setName: "durationPrediction", queryFunction: "get_stop_duration_this_month_prediction", message: "get_stop_duration_this_month_prediction is requested", requiresFilter: true},
  { workerName: "weightUnloaded7DaysAPI",filename: "stops", setName: "weight7DaysAggregates", queryFunction: "get_weight_unloaded_last_week", message: "get_stop_duration_last_week is requested", requiresFilter: true},
  { workerName: "comparedWeightUnloaded7DaysAPI", filename: "stops", setName: "comparedWeight7DaysAggregates", queryFunction: "get_comparison_weight_unloaded_last_week", message: "get_comparison_stop_duration_last_week is requested", requiresFilter: true},
  { workerName: "weightUnloadedPredictionAPI", filename: "stops", setName: "weightPrediction", queryFunction: "get_weight_unloaded_this_month_prediction", message: "get_stop_duration_this_month prediction is requested", requiresFilter: true},
  { workerName: "weightUnloadedPredictionAPI", filename: "stops", setName: "weightPrediction", queryFunction: "get_weight_unloaded_this_month_prediction", message: "get_stop_duration_this_month prediction is requested", requiresFilter: true},
  { workerName: "dataForStoryComponent", filename: "stops", setName: "storyData", queryFunction: "get_status", message: "get_status is requested", requiresFilter: true},
  { workerName: "topVehicles", filename: "stops", setName: "topVehicles", queryFunction: "get_vehicle_top", message: "get_vehicle_top is requested", requiresFilter: true },
  { workerName: "topLocations", filename: "stops", setName: "topLocations", queryFunction: "get_location_top", message: "get_location_top is requested", requiresFilter: true},
  { workerName: "topFuelConsumptionVehicles", filename: "stops", setName: "topFuelConsumptionVehicles", queryFunction: "get_fuel_consumption_vehicle_top", message: "get_fuel_consumption_vehicle_top is requested", requiresFilter: true},
  { workerName: "kpiLocation", filename: "kpi", setName: "kpiLocation", queryFunction: "get_fleet_kpi", message: "get_fleet_kpi is requested", requiresFilter: false},
  { workerName: "vehicleRegNos", filename: "stops", setName: "vehicleRegNos", queryFunction: "get_regno", message: "get_regno is requested",requiresFilter: true},
  { workerName: "vehicleAggregates", filename: "stops", setName: "vehicleAggregates", queryFunction: "get_vehicle_aggregates", message: "get_vehicle_aggregates is requested", requiresFilter: true }
]
const apiConfigurationsRef = ref(apiConfigurations)


const fetchFilteredData = (metricType, filterValue, dates = null) => {
  apiConfigurationsRef.value.forEach(apiConfig => {
    const worker = workerManager.getWorker(apiConfig.workerName)
    const baseFilter = dates || {}
    const filters = apiConfig.requiresFilter ? { ...baseFilter, ...getFilter(metricType, filterValue) } : {}
    const configWithFilters = { ...apiConfig, filters }
    postMessageToDuckAPI(worker, configWithFilters)
  })
}

const handleDataReady = (data, workerName) => {
  log("info", `${workerName} is filtered and fetched!`)

}


const { postMessageToDuckAPI} = useDataFetcher(apiConfigurationsRef, {
  onDataReceived: handleDataReady
})

</script>

<style scoped>
.dashboard-overlay {
  position: absolute;
  top: 50px;
  left: 5px;
  right: 0;
  bottom: 0;
  width: 100%;
  height: calc(100% - 50px);
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 9;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px;
}

.metrics-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  width: 80%;
  max-width: 1200px;
}
.metrics-container{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
  padding: 1rem;
  width: 100%;
}
.metric-card {
  display: flex;
  flex-direction: column;
  padding: 25px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

.metric-card h3 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
  height: 50px;
}

.metric-content > div {
  padding: 15px 0;
  border-bottom: 1px solid #eee;
}

.metric-content > div:last-child {
  border-bottom: none;
}

.location-hex, .vehicle-id {
  font-weight: bold;
  color: #444;
  margin-bottom: 8px;
}

.duration {
  color: #666;
  margin-bottom: 4px;
}

.stops, .saving {
  color: #888;
  font-size: 0.9em;
}

.metric-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.clear-filter-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 1rem 1rem;
  gap: 1rem;
}



.clear-filter-button {
  min-width: 120px;
  text-align: center;
}

</style>
