<template>
  <div :id="id" />
</template>


<script setup>
import { useEventsBinding, useMap } from "@/composables/index.js"
import { FlowmapLayer } from "@flowmap.gl/layers"
import { latLngToCell, cellToLatLng } from "h3-js"
import "mapbox-gl/dist/mapbox-gl.css"
import {computed, onMounted, onUnmounted, ref, unref, markRaw, inject, watch} from "vue"
import { log } from "@/plugin/logger.js"
import { MapboxLayer } from "@deck.gl/mapbox"
import {useSetStore} from "@/store/setStore"
import mapboxgl from "mapbox-gl"
import {useMainStore} from "@/store/mainStore"
import {workerManager} from "@/services/duckWorkerManager"
import {useDataFetcher} from "@/composables/useCustomerDataFetcher"
const H3 = require("h3-js")

const events = [
  "mousedown",
  "mouseup",
  "click",
  "dblclick",
  "decklayercolorstyle",
  "decklayervisible",
  "decklayerfilter",
  "layerDataChanged",
  "mousemove",
  "mouseenter",
  "mouseleave",
  "mouseover",
  "mouseout",
  "contextmenu",
  "styleChanged",
  "touchstart",
  "touchend",
  "touchcancel",
  "selectedLocationInFlow"
]
const props = defineProps({
  id: {
    type: String,
    required: true
  },
  beforeId: {
    type: String,
    default: undefined
  },
  options: {
    type: Object,
    default: () => {}
  },
  getDataFunction: {
    type: String,
    required: true
  },
  accessorKeyForDataFunction: {
    type: String,
    required: true
  },
  magnitudeVariable: {
    type: String,
    default: "median_trip_weight"
  },
  colorScheme: {
    type: String,
    default: "Magenta"
  },
  namedModule: {
    type: String,
    required: true
  }
})

const dataStore = useSetStore()
const useCustomStore = inject(props.namedModule)
const mainStore = useMainStore()
const { map } = useMap()
const isLoaded = ref(false)
// eslint-disable-next-line vue/valid-define-emits
const emit = defineEmits()

const cachedData = ref({})
const selectedLocations = ref([])

const getClusterLevelsH3 = (locations, minZoom = 1, maxZoom = 20) => {
  let nodes = locations.map((d) => ({
    id: d.id,
    zoom: maxZoom,
    lat: +d.lat,
    lon: +d.lon
  }))

  const result = []
  let rawZoom = null

  for (let zoom = maxZoom - 1; zoom >= minZoom; zoom--) {
    const h3Zoom = zoom - 4
    const nodesByH3 = nodes.reduce((acc, d) => {
      const h3Id = latLngToCell(+d.lat, +d.lon, h3Zoom)
      if (!acc[h3Id]) {
        acc[h3Id] = []
      }
      acc[h3Id].push(d)
      return acc
    }, {})

    const keys = Object.keys(nodesByH3)
    if (keys.length < locations.length) {
      if (rawZoom === null) {
        rawZoom = zoom + 1
      }
      nodes = keys.map((id) => {
        if (nodesByH3[id].length === 1) {
          const node = nodesByH3[id][0]
          return {
            id: `{[${node.id}:${zoom}]}`,
            zoom,
            lat: node.lat,
            lon: node.lon,
            children: [node.id]
          }
        }
        return {
          id: `{[${id}:${zoom}]}`,
          zoom,
          lat: cellToLatLng(id, true)[0],
          lon: cellToLatLng(id, true)[1],
          children: nodesByH3[id].map((d) => d.id)
        }
      })

      result.unshift({
        zoom,
        nodes
      })
    }

    if (keys.length <= 1) {
      break
    }
  }

  result.push({
    zoom: rawZoom ?? maxZoom,
    nodes: locations
  })

  return result
}

const fetchDataSync = (clusterMethod = "HCA", magnitude = props.magnitudeVariable) => {
  const edges = useCustomStore.getAggregate(props.accessorKeyForDataFunction) ? useCustomStore.getAggregate(props.accessorKeyForDataFunction) : dataStore.getAggregate(props.accessorKeyForDataFunction)

  const origins = new Set(edges.map(l => { return l.hexagons_origin }))
  const destinations = new Set(edges.map(l => { return l.hexagon_destination }))
  const locations = [...new Set([...origins, ...destinations])].map(loc => ({
    id: loc,
    name: "hexid:" + loc,
    lat: cellToLatLng(loc, true)[0],
    lon: cellToLatLng(loc, true)[1]
  }))

  const flows = edges.map((row) => ({
    origin: row.hexagons_origin,
    dest: row.hexagon_destination,
    magnitude: Number(row[magnitude])
  }))

  cachedData.value = { locations, flows }
  return {
    ...cachedData.value,
    ...(clusterMethod === "H3"
        ? { clusterLevels: getClusterLevelsH3(cachedData.value.locations) }
        : null)
  }
}

const rawData = fetchDataSync()
const nonReactiveData = markRaw(rawData)

const duckAPI = new Worker(
    new URL("../web-worker/duckdbWorker.js", import.meta.url),
    { type: "module" }
)

const flowLayer = new MapboxLayer({
  id: props.id,
  type: FlowmapLayer,
  data: nonReactiveData,
  pickable: true,
  clusteringEnabled: true,
  clusteringMethod: "HCA",
  clusteringAuto: true,
  fadeEnabled: true,
  maxTopFlowsDisplayNum: 300,
  locationLabelsEnabled: false,
  colorScheme: props.colorScheme,
  getLocationId: (loc) => loc.id,
  getLocationLat: (loc) => loc.lat,
  getLocationLon: (loc) => loc.lon,
  getFlows: (flow) => flow,
  getFlowOriginId: (flow) => flow.origin,
  getFlowDestId: (flow) => flow.dest,
  getFlowMagnitude: (flow) => flow.magnitude,
  getLocationName: (loc) => loc.name,
  onClick: (flowComponent, event) => {
    event.stopPropagation()
    flowComponent.object.type=== "location"?setSelectedHexagons({coordinates: flowComponent.coordinate}):fetchTripData({destination: flowComponent.object.dest.children?flowComponent.object.dest.children.join(","):flowComponent.object.flow.dest,
      origin: flowComponent.object.origin.children?flowComponent.object.origin.children.join(","):flowComponent.object.flow.origin})
    //emit("mb-feature-click", { coordinates: flowComponent.coordinate, properties: flowComponent.object })

  }
})

const apiConfigurations = [
  { workerName: "hexesAPI", filename: "stops", setName: ["locationAggregates", "locationAggregatesUnfiltered"], queryFunction: "get_location_aggregates", message: "Hexes is requested", requiresFilter: true},
  { workerName: "edgesAPI", filename: "stops", setName: ["edgeAggregates", "edgeAggregatesUnfiltered"], queryFunction: "edges", message: "Edges is requested", requiresFilter: true},
  { workerName: "stopDuration7DaysAPI",filename: "stops", setName: "duration7DaysAggregates", queryFunction: "get_stop_duration_last_week", message: "get_stop_duration_last_week is requested", requiresFilter: true},
  { workerName: "comparedStopDuration7DaysAPI", filename: "stops", setName: "comparedDuration7DaysAggregates", queryFunction: "get_comparison_stop_duration_last_week", message: "get_comparison_stop_duration_last_week is requested", requiresFilter: true},
  { workerName: "stopDurationPredictionAPI", filename: "stops", setName: "durationPrediction", queryFunction: "get_stop_duration_this_month_prediction", message: "get_stop_duration_this_month_prediction is requested", requiresFilter: true},
  { workerName: "weightUnloaded7DaysAPI",filename: "stops", setName: "weight7DaysAggregates", queryFunction: "get_weight_unloaded_last_week", message: "get_stop_duration_last_week is requested", requiresFilter: true},
  { workerName: "comparedWeightUnloaded7DaysAPI", filename: "stops", setName: "comparedWeight7DaysAggregates", queryFunction: "get_comparison_weight_unloaded_last_week", message: "get_comparison_stop_duration_last_week is requested", requiresFilter: true},
  { workerName: "weightUnloadedPredictionAPI", filename: "stops", setName: "weightPrediction", queryFunction: "get_weight_unloaded_this_month_prediction", message: "get_stop_duration_this_month prediction is requested", requiresFilter: true},
  { workerName: "weightUnloadedPredictionAPI", filename: "stops", setName: "weightPrediction", queryFunction: "get_weight_unloaded_this_month_prediction", message: "get_stop_duration_this_month prediction is requested", requiresFilter: true},
  { workerName: "dataForStoryComponent", filename: "stops", setName: "storyData", queryFunction: "get_status", message: "get_status is requested", requiresFilter: true},
  { workerName: "topVehicles", filename: "stops", setName: "topVehicles", queryFunction: "get_vehicle_top", message: "get_vehicle_top is requested", requiresFilter: true },
  { workerName: "topLocations", filename: "stops", setName: "topLocations", queryFunction: "get_location_top", message: "get_location_top is requested", requiresFilter: true},
  { workerName: "topFuelConsumptionVehicles", filename: "stops", setName: "topFuelConsumptionVehicles", queryFunction: "get_fuel_consumption_vehicle_top", message: "get_fuel_consumption_vehicle_top is requested", requiresFilter: true},
  { workerName: "kpiLocation", filename: "kpi", setName: "kpiLocation", queryFunction: "get_fleet_kpi", message: "get_fleet_kpi is requested", requiresFilter: false},
  { workerName: "vehicleRegNos", filename: "stops", setName: "vehicleRegNos", queryFunction: "get_regno", message: "get_regno is requested",requiresFilter: true},
  { workerName: "vehicleAggregates", filename: "stops", setName: "vehicleAggregates", queryFunction: "get_vehicle_aggregates", message: "get_vehicle_aggregates is requested", requiresFilter: true }
]
const apiConfigurationsRef = ref(apiConfigurations)


const fetchTripData = ( filterValue, dates = null) => {
  apiConfigurationsRef.value.forEach(apiConfig => {
    const worker = workerManager.getWorker(apiConfig.workerName)
    const baseFilter = dates || {}
    const filters = apiConfig.requiresFilter ? { ...baseFilter, ...getFilter( filterValue) } : {}
    const configWithFilters = { ...apiConfig, filters }
    postMessageToDuckAPI(worker, configWithFilters)
  })
}
const get1monthAgoDate = () => {
  if (process.env.VUE_APP_API_CLIENT !== "prod") {
    return "2021-10-01"
  }

  const currentDate = new Date() // Today
  currentDate.setMonth(currentDate.getMonth() - 1) // Subtract 1 month

  const year = currentDate.getFullYear()
  const month = String(currentDate.getMonth() + 1).padStart(2, "0") // Months are 0-indexed
  const day = String(currentDate.getDate()).padStart(2, "0")

  return `${year}-${month}-${day}`
}

const dateRange = ref([
  new Date(get1monthAgoDate()),
  new Date()
])

const getFilter = ( filterValue) => {
  const baseFilter = {
    min_date: dateRange.value[0].toISOString().split("T")[0],
    max_date: dateRange.value[1].toISOString().split("T")[0]
  }
  return { ...baseFilter, destination: filterValue.destination,
    origin: filterValue.origin }

}
const handleDataReady = (data, workerName) => {
  log("info", `${workerName} is filtered and fetched!`)
}
const { postMessageToDuckAPI} = useDataFetcher(apiConfigurationsRef, {
  onDataReceived: handleDataReady
})


function setSelectedHexagons (event) {
  const selectedState = flowLayer.deck._lastPointerDownInfo.layer.state
  const clusterIndex = selectedState.dataProvider.getClusterIndex()
  const selectedClusterId = selectedState.pickingInfo.object.location?.id
  const h3UUIDRegex = /^[0-9a-f]{15}$/i

  if (!h3UUIDRegex.test(selectedClusterId)) {
    const selectedCluster = clusterIndex.getClusterById(selectedClusterId)
    selectedLocations.value = clusterIndex.expandCluster(selectedCluster)
    unref(map).fire("selectedLocationInFlow", { coordinates: event.coordinates, selectedLocations: selectedLocations.value })
    return
  }
  selectedLocations.value = [selectedClusterId]


  unref(map).fire("selectedLocationInFlow", { coordinates: event.coordinates, selectedLocations: selectedLocations.value })
}

function handleDeckLayerDataUpdated (event) {
  if (event.layerName === props.id) {
    const rawData = fetchDataSync()
    const nonReactiveData = markRaw(rawData)
    flowLayer.setProps({
      updateTriggers: {
        data: nonReactiveData
      },
      data: nonReactiveData
    })
  }
}
function handleDeckLayerVisibility (event) {
  if (event.layerName === props.id) {
    flowLayer.setProps({
      visible: event.visibility
    })
  }
}
function filterData(data, filters) {
  return data.filter(obj => {
    return filters.every(filter => {
      const fieldValue = obj[filter.selectedTexts[0]]
      if (filter.selectedInputType === "number") {
        return fieldValue >= parseFloat(filter.selectedRanges[0]) && fieldValue <= parseFloat(filter.selectedRanges[1])
      } else if (filter.selectedInputType === "date") {
        const startDate = new Date(filter.selectedDates[0])
        const endDate = new Date(filter.selectedDates[1])
        const objDate = new Date(fieldValue)
        return objDate >= startDate && objDate <= endDate
      } else {
        if (filter.selectedSplitStrings[0]!== ""&& filter.selectedSplitStrings.length>0 ) {
          return (filter.selectedSplitStrings).includes(fieldValue)
        } else {
          return true
        }
      }
    })
  })
}

function handleDeckLayerFilter (event) {
  if (event.layerName === props.id) {
    const filteredData = filterData(useCustomStore[props.getDataFunction], event.arrayFilter)
    const newData = markRaw(fetchDataSync("HCA", props.magnitudeVariable, filteredData))
    
    flowLayer.setProps({
      updateTriggers: {
        data: newData
      },
      data: newData
    })
  }
}
function handleDeckLayerColorChange (event) {
  if (event.layerName === props.id) {
    flowLayer.setProps({
      colorScheme: event.colourScheme,
      data: fetchDataSync("HCA", event.colorPropKey)
    })
  }
}

const options = computed(() => {
  const opts = { ...props.options, id: props.id }
  if (opts.paint === null || opts.paint === undefined) {
    delete opts.paint
  }
  if (opts.layout === null || opts.layout === undefined) {
    delete opts.layout
  }
  return opts
})

useEventsBinding(emit, map, events, props.id)

/**
 * Remove the layer.
 */
function removeLayer() {
  if (typeof unref(map).getLayer(props.id) !== "undefined") {
    unref(map).removeLayer(props.id)
  }
}

onMounted(() => {
  useCustomStore.setAggregate(props.accessorKeyForDataFunction, dataStore.getAggregate(props.accessorKeyForDataFunction))
  removeLayer()
  unref(map).addLayer(flowLayer)
  useCustomStore.setMapLayerProp("Trip", parseInt(props.id.split("_").pop()), "mbId", props.id)

  log("info", "Initiated a 'FlowLayer' with" + props.id + " name.")
  unref(map).setLayoutProperty(props.id, "visibility", "visible")

  // listen to custom event for deck
  map.value.on("decklayervisible", handleDeckLayerVisibility)
  map.value.on("decklayercolorstyle", handleDeckLayerColorChange)
  map.value.on("decklayerfilter", handleDeckLayerFilter)
  map.value.on("layerDataChanged", handleDeckLayerDataUpdated)
  map.value.on("styleChanged", function() {
    removeLayer()

    // Re-attaching the layer to the map when the custom event is heard
    unref(map).addLayer(flowLayer)
    unref(map).setLayoutProperty(props.id, "visibility", "visible")
  })
  unref(map).triggerRepaint()
  isLoaded.value = true
  emit("edges-loaded")
  duckAPI.onmessage = (event) => {
    if (event.data.key === "done") {
      emit("mb-matrix-loaded", { properties: event.data.value })
    }
  }
})

onUnmounted(() => {
  removeLayer()
})

watch(
  () => useCustomStore.getAggregate(props.accessorKeyForDataFunction),
  (newData) => {
    if (newData) {
      const newFlowData = markRaw(fetchDataSync("HCA", props.magnitudeVariable))
      flowLayer.setProps({
        updateTriggers: {
          data: newFlowData
        },
        data: newFlowData
      })
    }
  },
  { deep: true }
)

</script>