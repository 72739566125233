<template>
  <aggregate-card
    v-show="travelSequenceSet && isVisible"
    class="travel-window"
  >
    <template #content>
      <div class="card-header">
        <v-btn
          class="closeModal"
          variant="outlined"
          icon
          @click="closeModal"
        >
          <tds-icon
            class="closeModalIcon"
            name="cross"
          />
        </v-btn>
      </div>
      <v-table>
        <tbody>
          <tr>
            <td>From:</td>
            <td>{{ origin }}</td>
          </tr>
          <tr>
            <td>To:</td>
            <td>{{ destination }}</td>
          </tr>
        </tbody>
      </v-table>
      <div style="display: flex;padding: 15px;gap: 6px;">
        <div
          v-if="optimalTrip"
          class="optimal-trip-info"
        >
          <div class="trip-header">
            <h3>Average Travel Time</h3>
          </div>

          <div class="trip-details">
            <div class="detail-item">
              <span class="label">Departure Time:
                <span class="value">{{ formattedData.median_departure_timestamp.time }}
                </span></span>
            </div>
            <div class="detail-item">
              <span class="label">Departure Day:
                <span class="value">{{ formattedData.median_departure_timestamp.day }}
                </span></span>
            </div>
            <div class="detail-item">
              <span class="label">Duration:
                <span class="value">{{ formattedData.median_trip_duration }}
                </span></span>
            </div>
          </div>
        </div>
        <div
          v-if="optimalTrip"
          class="optimal-trip-info"
        >
          <div class="trip-header">
            <h3>Shortest Travel Time</h3>
          </div>

          <div class="trip-details">
            <div class="detail-item">
              <span class="label">Departure Time:
                <span class="value">{{ formattedData.min_departure_timestamp.time }}
                </span></span>
            </div>
            <div class="detail-item">
              <span class="label">Departure Day:
                <span class="value">{{ formattedData.min_departure_timestamp.day }}
                </span></span>
            </div>
            <div class="detail-item">
              <span class="label">Duration:
                <span class="value">{{ formattedData.min_trip_duration }}
                </span></span>
            </div>
          </div>
        </div>
        <div
          v-if="optimalTrip"
          class="optimal-trip-info"
        >
          <div class="trip-header">
            <h3>Longest Travel Time</h3>
          </div>

          <div class="trip-details">
            <div class="detail-item">
              <span class="label">Departure Time:
                <span class="value">{{ formattedData.max_departure_timestamp.time }}
                </span></span>
            </div>
            <div class="detail-item">
              <span class="label">Departure Day:
                <span class="value">{{ formattedData.max_departure_timestamp.day }}
                </span></span>
            </div>
            <div class="detail-item">
              <span class="label">Duration:
                <span class="value">{{ formattedData.max_trip_duration }}
                </span></span>
            </div>
          </div>
        </div>
        <div
          v-else-if="clusterOverlap"
          class="optimal-trip-info"
        >
          <div class="trip-header">
            <h3>The distances between the points have potential overlap.</h3>
            <i>Select locations that have a greater distance by carefully zooming in to the locations you select.</i>
          </div>
        </div>
      </div>
    </template>
  </aggregate-card>
</template>

<script setup>
import AggregateCard from "@/components/AggregateCard.vue"
import { useEventsBinding, useMap } from "@/composables"
import { log } from "@/plugin/logger"
import { authService } from "@/services/AuthService"
import { workerManager } from "@/services/duckWorkerManager"
import { humanFriendlyH3Name } from "@/services/h3Utils"
import { useSetStore } from "@/store/setStore"
import { cellToLatLng } from "h3-js"
import * as turf from "turf"
import { computed, onMounted, onUnmounted, ref, watch } from "vue"

const props = defineProps({
  mapLayers: {
    type: Array,
    required: true
  }
})

const { map } = useMap()
const mapevents = ["selectedLocationInFlow"]
const dataStore = useSetStore()
const isVisible = ref(false)
// eslint-disable-next-line vue/valid-define-emits
const emit = defineEmits()
const setLast = ref("")

const origin = ref(null)
const originHexagons = ref(null)
const originCoordinate = ref(null)
const destination = ref(null)
const destinationHexagons = ref(null)
const destinationCoordinate = ref(null)
const clusterOverlap = ref(false)
const optimalTrip = ref(null)
const formattedData = ref({})
const apiConfigurations = [
  { workerName: "optimalTrip", setName: "", queryFunction: "get_optimal_trip", layer: "stops", message: "Optimal Trip time is requested" }
]

const registerListeners = () => {
  apiConfigurations.forEach(config => {
    workerManager.registerListener(config.workerName, (event) => handleWorkerResponse(event))
  })
}

const deregisterListeners = () => {
  apiConfigurations.forEach(config => {
    workerManager.deregisterListener(config.workerName)
  })
}

const asBearerToken = (token) => {
  return `Bearer ${token}`
}

const postMessageToDuckAPI = (worker, workerName, query_func, filename, logMessage) => {
  const token = asBearerToken(authService.keycloak.token)
  const duckEndpoint = process.env.VUE_APP_DUCK_API_B
  const customer = authService.keycloak.tokenParsed.customer.external_customer_reference
  log("info", logMessage)
  worker.postMessage({
    method: "getResultsFromDuckDB",
    workerName: workerName,
    args: [token, duckEndpoint,
      {
        trip_origin: originHexagons.value.join(","),
        trip_destination: destinationHexagons.value.join(",")
      },
      {
        query_func: query_func,
        layer: "trips"
      },
      [
        {
          filename: filename,
          file_suffix: "snappy.parquet"
        }
      ],
      {
        customer: customer
      }
    ]
  })
}

const calculatedMaxDistance = (h3Indices) => {
  let maxDistance = 0
  let furthestPair = []

  h3Indices.forEach((index1, idx1) => {
    h3Indices.forEach((index2, idx2) => {
      if (idx1 < idx2) { // Ensure each pair is only checked once
        const geo1 = cellToLatLng(index1) // [lat, lng]
        const geo2 = cellToLatLng(index2) // [lat, lng]

        const point1 = turf.point([geo1[1], geo1[0]])
        const point2 = turf.point([geo2[1], geo2[0]])

        const distance = turf.distance(point1, point2)

        if (distance > maxDistance) {
          maxDistance = distance
          furthestPair = [index1, index2]
        }
      }
    })
  })
  return maxDistance
}

const requestOptimalTrip = () => {
  clusterOverlap.value = false
  apiConfigurations.forEach(apiConfig => {
    const worker = workerManager.getWorker(apiConfig.workerName)
    postMessageToDuckAPI(
        worker,
        apiConfig.workerName,
        apiConfig.queryFunction,
        apiConfig.layer,
        apiConfig.message
    )
  })
}
const closeModal = () => {
  isVisible.value = !isVisible.value
}

const travelSequenceSet = computed(() => {
  return origin.value !== null || destination.value !== null
})

function setValueFromLocations(locations, moreCount, defaultValue = null) {
  if (locations && locations.length > 0) {
    let moreText = moreCount > 1 ? ` & ${moreCount - 1} more` : ""
    return `${locations[0]}${moreText}`
  }
  return defaultValue
}

const handleUpdatedSelection = async (event) => {
  isVisible.value = true
  const targetHexagons = event.selectedLocations

  const getFormattedLocationName = async (hexId) => {
    const humanName = await humanFriendlyH3Name(hexId, "reverse_geocode")
    return `${humanName} (${hexId})`
  }

  const formatLocations = async (hexagons, moreCount) => {
    if (!hexagons || hexagons.length === 0) return null

    const mainLocation = await getFormattedLocationName(hexagons[0])
    const moreText = moreCount > 1 ? ` & ${moreCount - 1} more` : ""
    return `${mainLocation}${moreText}`
  }

  const moreCount = event.selectedLocations ? event.selectedLocations.length : 0

  if (setLast.value === "" || setLast.value === "destination") {
    originHexagons.value = event.selectedLocations
    originCoordinate.value = event.coordinates
    origin.value = await formatLocations(event.selectedLocations, moreCount)
    setLast.value = "origin"
  } else if (setLast.value !== "" || setLast.value === "origin") {
    destinationHexagons.value = event.selectedLocations
    destinationCoordinate.value = event.coordinates
    destination.value = await formatLocations(event.selectedLocations, moreCount)
    setLast.value = "destination"
  } else {
    originHexagons.value = event.selectedLocations
    originCoordinate.value = event.coordinates
    origin.value = await formatLocations(event.selectedLocations, moreCount)
    setLast.value = "origin"
  }
}

const handleWorkerResponse = (event) => {
  if (event.data.key === "done") {
    optimalTrip.value = event.data.value.result[0]
    const processedData = processAllProperties(optimalTrip.value)
    formattedData.value = processedData
  }
}

const processAllProperties = (data) => {
  const formattedData = {}

  for (const [key, value] of Object.entries(data)) {
    if (key.toLowerCase().includes("timestamp")) {
      formattedData[key] = readableResponseData(value)
      formattedData[`${key}_raw`] = value
    } else {
      formattedData[key] = value
    }
  }

  return formattedData
}

const readableResponseData = (timestamp) => {
  const date = new Date(timestamp)
  const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
  const dayName = days[date.getDay()]
  const time = date.toLocaleTimeString()

  return {
    day: dayName,
    time: time
  }
}


onMounted(() => {
  registerListeners()
  props.mapLayers.map(layer => {
    useEventsBinding(emit, map, mapevents, layer.layerName+"_travel")
  })
  map.value.on("selectedLocationInFlow", handleUpdatedSelection)
})

onUnmounted(() => {
  deregisterListeners()
})

watch([originHexagons, destinationHexagons], ([newVal1, newVal2], [oldVal1, oldVal2]) => {
  if (newVal1 !== null && newVal2 !== null) {
    const originDistances = calculatedMaxDistance(originHexagons.value)
    const destinationDistances = calculatedMaxDistance(destinationHexagons.value)
    const clusterCentroidOrigin = turf.point([originCoordinate.value[1], originCoordinate.value[0]])
    const clusterCentroidDestination = turf.point([destinationCoordinate.value[1], destinationCoordinate.value[0]])

    const distanceBetweenClusters = turf.distance(clusterCentroidOrigin, clusterCentroidDestination)

    if (distanceBetweenClusters > (originDistances + destinationDistances)) requestOptimalTrip()
    else clusterOverlap.value = true
  }
})

</script>
<style scoped>
.optimal-trip-info {
  padding: 1rem;
  border-radius: 8px;
  background-color: #f8f9fa;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.trip-header {
  margin-bottom: 1rem;
}

.trip-header h3 {
  margin: 0;
  color: #2c3e50;
  font-size: 1.25rem;
}

.trip-details {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.detail-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.label {
  font-weight: 600;
  color: #4a5568;
  min-width: 120px;
}

.value {
  color: #2d3748;
}


</style>
<style scoped>
.card-header {
  display: flex;
  flex-direction: row;
}

.closeModal {
  background-color: transparent;
  border: none;
  align-self: flex-end;
}

.closeModalIcon {
  height: 20px;
  width: 20px;
}

.travel-window {
  width: 500px;
  position: absolute;
  z-index: 20;
  top: 0;
  left: 0;
  margin-left: 5px;
  margin-top: 60px;
}
</style>